.container__navigation {
  box-sizing: border-box;
  display: flex;
  flex-direction: column; }
pow {
  > * {
    box-sizing: border-box; } }

.container__body {

  h2 {
    font-size: 150%;
    padding: 2rem 1rem 0;
    text-align: center; } }

.container__search {
  padding: 1rem 1rem 0;

  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    font-size: inherit;
    border: 1px solid $border-color;
    width: 100%;
    height: 2rem; } }

@media screen and (max-width: 899px) {
  .container__navigation {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.2s ease-in-out;
    overflow-y: auto;
    background-color: $light-bg-color; }

  .container__navigation.menuVisible {
    transform: translate3d(0, 0, 0); }

  .container__body {
    transform: translate3d(0px, 0, 0);
    transition: transform 0.2s ease-in-out;

    .container.is-open & {
      transform: translate3d(100%, 0, 0); }

    h2 {
      font-size: 125%;
      padding-bottom: 1rem; } } }

@media screen and (min-width: 900px) {
  .container {
    display: flex;
    padding: 0; }

  .container__navigation {
    position: fixed;
    height: 100vh;
    width: 35%;
    box-sizing: border-box;
    background-color: $light-bg-color; }

  .container__body {
    flex: 1;
    margin-left: 35%; }

  .container__body--white {
    background-color: white; }

  .container__mobileHeader {
    display: none; } }
