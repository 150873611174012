.showcase {
  display: flex;
  flex-wrap: wrap; }

.showcase.is-loading {
  visibility: hidden; }

.showcase__item {
  margin-bottom: 1rem;
  display: flex; }

.showcase__item .card {
  background: #ffffff; }

@media screen and (max-width: 600px) {
  .showcase {
    margin: 0 1rem; }

  .showcase__item {
    width: 100%; } }

@media screen and (min-width: 601px) {
  .showcase {
    margin-right: 1rem; }

  .showcase__item {
    box-sizing: border-box;
    width: (100% / 2);
    float: left;
    padding-left: 1rem; } }

@media screen and (min-width: 1400px) {
  .showcase__item {
    width: (100% / 3); } }

@media screen and (min-width: 900px) {
  .showcase {
    padding: 1rem 0 1rem;
    margin-right: 1rem; } }

@media screen and (min-width: 1100px) {
  .showcase__item {
    padding-left: 1rem; } }
