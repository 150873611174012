@import "./variables/colors";
@import "./reset";
@import "./blocks/card";
@import "./blocks/container";
@import "./blocks/mobile-header";
@import "./blocks/navigation";
@import "./blocks/recipe";
@import "./blocks/sheet";
@import "./blocks/showcase";
@import "./blocks/social";
@import "./blocks/images";


.tag {
  border-radius: .5rem;
  color: white;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: .75rem;
  margin-bottom: 1rem;
  padding: 0;
  width: auto; }

.loadingIndicator {
  padding: 1rem; }

@media screen and (min-width: 900px) {
  body {
    overflow-y: scroll; } }
