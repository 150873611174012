.navigation__title {
  margin-bottom: 1rem;
  padding: 4rem 2rem 0;
  display: flex;
  align-items: center;

  a {
    font-size: 2.5rem;
    text-decoration: none;
    line-height: 1;
    color: $dark-body-color;

    &:hover {
      border-bottom: dotted 1px #9055a2; } }

  img {
    margin-right: 1rem;
    height: 4rem;
    width: 4rem; } }


.navigation__intro {
  color: $light-body-color;
  font-size: 1.25rem;
  padding: 2rem;

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0; } } }

.navigation__menu {
  margin-bottom: 20px;

  li {
    a {
      text-decoration: none;
      display: block;
      padding-left: 20px;
      color: $base-body-color;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -6px;
        height: 12px;
        width: 12px;
        background: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/chevron-right.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.6; }

      &:hover {
        color: $active-color; } }

    &.is-active a {
      font-weight: bold;
      color: $active-color; } } }

.navigation__backlink {
  padding: 0 2rem;

  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

.navigation__filter {
  overflow-y: auto;
  padding: 2rem 2rem 2rem 0;
  margin-left: 2rem;
  border-top: 1px dashed $border-color;
  border-bottom: 1px dashed $border-color; }

.navigation__tag {
  display: block;
  text-transform: lowercase;

  & label {
    padding-left: .5rem;
    text-transform: capitalize;

    &:hover {
      cursor: pointer; } } }

.navigation__footer {
  box-sizing: border-box;
  padding: 2rem 2rem 4rem 0;
  margin: 0 0 0 2rem;
  background: $light-bg-color; }

.navigation__social {
  display: inline-block;
  margin-right: 1rem; }

.navigation__copyright {
  color: $light-body-color;
  display: inline-block;
  font-size: .875rem;
  text-transform: uppercase;
  letter-spacing: 2px; }


@media screen and (max-width: 899px) {

  .navigation__title {
    padding: 2rem 1rem 0 1rem; }

  .navigation__intro {
    padding: 1rem; }

  .navigation__filter {
    padding: 1rem 0;
    margin-left: 1rem;
    margin-right: 1rem; }

  .navigation__footer {
    padding: 1rem;
    margin-left: 0; }

  .navigation__copyright {
    margin-top: 1rem; } }

@media screen and (max-width: 400px) {

  .navigation__title {
    img {
      height: auto;
      width: auto; }
    a {
      font-size: 125%; } }

  .navigation__intro {
    font-size: 100%; } }
