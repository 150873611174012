.image-list {

  thead {
    text-transform: uppercase; }

  th, td {
    border: 1px solid #dedede;
    padding: 1rem;
    vertical-align: top; }

  th {
    font-weight: bold;
    text-align: left; } }

.image-list__image {
  width: 200px; }


