.mobile-header {
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  justify-content: left;
  width: 100%;
  align-items: center;

  > * {
    box-sizing: border-box; } }

.mobile-header__primary {
  display: flex;
  margin: 0 0 1rem; }

.mobile-header__logo {
  height: 50%; }

.mobile-header__title {
  display: inline-block;
  text-decoration: none;

  a {
    padding: .5rem .5rem;
    margin-left: .5rem;
    color: $dark-body-color;
    text-decoration: none; } }

.mobile-header__menu {
  margin-left: auto;
  padding: 1rem;
  display: inline-block;
  margin-top: .25rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/bars.svg');
  background-color: $border-color;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  border: 5px solid transparent;

  &:hover {
    cursor: pointer; } }

@media screen and (min-width: 900px) {
  .mobile-header {
    display: none; } }

@media screen and (max-width: 899px) {
  .mobile-header__title {
    font-size: 150%; } }

@media screen and (max-width: 400px) {
  .mobile-header__title {
    font-size: 125%; } }
