.card {
  box-shadow: 0 0 .25rem #aaa;
  border-radius: .25rem;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 .25rem 1rem #888;
    cursor: pointer; } }

.card__image {
  display: block;
  position: relative;
  border-radius: 1rem;

  picture img {
    width: 100%; }

  img {
    display: block;
    border-radius: .25rem .25rem 0 0; }

  &:before, &:after {
    position: absolute;
    z-index: 1;
    content: "";
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: .25rem .25rem 0 0; } }

.card__caption {
  background-color: white;
  padding: 20px;
  border-radius: 0 0 .25rem .25rem; }

.card__title {
  display: block;
  line-height: 1;

  a {
    text-decoration: none;
    font-size: 1.5rem;
    color: $dark-body-color;

    &:hover {
      border-bottom: dotted 1px #9055a2; } } }

.card__description {
  color: $light-body-color;
  font-size: 1rem;
  line-height: 1.4; }
