.sheet {
  background-color: white; }

.sheet picture img {
  width: 100%; }

.sheet__inner {
  padding: 3rem 1rem;
  max-width: 600px; }

.sheet__title {
  font-size: 2.5em;
  color: $dark-body-color;
  line-height: 1; }

.sheet__lead {
  color: $light-body-color;
  font-size: 1.25rem;
  padding-bottom: 2rem;
  width: 100%; }

.sheet__tags {}

.sheet__gallery,
.sheet__slider {
  margin-bottom: 2rem; }

.sheet__cover {
  width: 100%;

  .gatsby-image-wrapper {
    max-height: 60vh; } }

.sheet__multiplier {
  p {
    padding: 1rem 0; } }

.sheet__body {
  color: $light-body-color;
  font-size: 1rem;
  margin-bottom: 2rem;

  h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0; } }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 40px;
    color: $dark-body-color; }

  h1 {
    font-size: 2rem; }

  .sheet__markdown h1 {
    font-size: 1.5rem; } // Compensate for fact that user enter content using h1 tags

  h2 {
    font-size: 1.25rem; }

  h3 {
    font-size: 1rem; }

  li {
    margin-left: 20px;
    margin-bottom: 5px; }

  ol li {
    list-style-type: decimal; }

  ul li {
    list-style-type: disc; }

  td {
    padding: 0.25rem 1rem 0.25rem 0;
    border-bottom: 1px solid #ddd;

    label {
      &:hover {}
      cursor: pointer; } }

  td:first-child {
    padding-right: .75rem; }

  td:nth-child(2) {
    white-space: nowrap;
    text-align: right; }

  tr:first-child td {
    border-top: 1px solid #ddd; }

  tr.checked td {
    color: #ddd; }

  .sheet__table-heading {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px; }

  blockquote {
    border-left: 4px solid $active-color;
    font-size: 1.1em;
    padding: 20px; } }

@media screen and (min-width: 900px) {
  .sheet__inner {
    padding: 6rem; }

  .sheet__body {
    font-size: 1rem;

    blockquote {
      padding-left: 40px; } } }
