.social {
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  border: 1px solid $border-color;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: 40%;
  background-repeat: no-repeat; }

.social--facebook {
  background-image: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/facebook-f.svg'); }

.social--twitter {
  background-image: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/twitter.svg'); }

.social--instagram {
  background-image: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/instagram.svg'); }

.social--email {
  background-image: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/envelope.svg'); }

@media screen and (min-width: 900px) {
  .social {
    width: 40px;
    height: 40px; } }
